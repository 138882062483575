import './App.css';
import Table from '@material-ui/core/Table';
import { LinearProgress, TableBody, TableCell, TableHead, TableRow, Typography, Card, AppBar } from '@material-ui/core';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

const App = () => {
  const [stocks, setStocks] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.log('Calling?')

    axios.get(`https://kq45q12k37.execute-api.us-east-1.amazonaws.com/NonProd/positions`)
      .then((resp) => setStocks(resp.data))
      .then(setLoading(true))
  }, []
  )

  console.log('BLINNK: ', stocks)


  if (loading === false) {
    return (
      <LinearProgress />
    )
  } else {
    return (
      <div className="App">
        <AppBar style={{ position: "inherit", minHeight: "48px", backgroundColor: "#404040" }}>
          <Typography variant="h3" style={{ marginTop: "8px" }} >Teater DU UI</Typography>
        </AppBar>
        <Card style={{ width: "1080px", margin: "auto", marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Symbol
                </TableCell>
                <TableCell>
                  Current Price
                </TableCell>
                <TableCell>
                  Purchase Price
                </TableCell>
                <TableCell>
                  Quantity
                </TableCell>
                <TableCell>
                  Mod Price
                </TableCell>
                <TableCell>
                  Total Gain/Loss
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { stocks.map((stock) => (
              <TableRow key={stock.key}>
                <TableCell>{stock.ticker}</TableCell>
                <TableCell>{stock.currentPrice}</TableCell>
                <TableCell>{stock.purchasePrice}</TableCell>
                <TableCell>{stock.owned}</TableCell>
                <TableCell>{stock.modPrice}</TableCell>
                <TableCell style={{color: !!(stock.gainLoss > 0) ? 'green': 'red'}}>{stock.gainLoss}</TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </div>
    )
  }

}
export default App;